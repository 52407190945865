import { Main, About_span, Spancenter } from "./About.styled";

const About = () => {
    return (
        <Main>
            <article>
                ТОО «Теміртау Қуат» зарегистрировано в Управлении Юстиции города
                Темиртау Департамента юстиции Карагандинской области за №
                325-1930-12 от 29 сентября 2006 года.
                <br />
                Форма собственности – <b>частная</b>. <br />Основным видом
                деятельности является оказание коммунальных услуг юридическим
                лицам (холодная вода, отвод сточных вод) и передача
                электрической энергии. Согласно Приказа Департамента Комитета по
                регулированию естественных монополий и защите конкуренции
                Министерства национальной экономики Республики Казахстан по
                Карагандинской области №5-ОД от 26.01.2007 ТОО «Теміртау Қуат»
                включено в местный раздел Государственного регистра субъектов
                естественных монополий по Карагандинской области по видам
                деятельности: подача воды по распределительным сетям, отвод
                сточных вод, передача и (или) распределение электрической
                энергии.
            </article>
            <About_span>Аналитическая справка</About_span>
            <Spancenter>
                По условиям надежности схемы электроснабжения 6-10 кВ
            </Spancenter>
            <span>
                ТОО «Темiртау Қуат» осуществляет электроснабжение «Восточной
                промзоны», «Западной промзоны», садоводческих кооперативов
                района Солонички, оздоровительных баз, детских оздоровительных
                лагерей и садоводческих кооперативов правого берега. <br />
                «Восточная промзона» получает питание:
            </span>
            <div className="table">
                <li>
                    с подстанции 110/6 кВ «Промбаза» от ячеек № 4, 9, 14 по ВЛ-6
                    кВ;
                </li>
                <li>
                    с подстанции 35/6 кВ «Бетонная» от ячеек № 1, 2, 7, 12, 16
                    по ВЛ-6 кВ;
                </li>
                <li>с подстанции 10/6 кВ «Куат» от ячеек № 2, 3 по ВЛ-6 кВ;</li>
                <li> с подстанции 110/6 кВ «КМС» от ячейки № 27 по ВЛ-6 кВ.</li>
            </div>
            <br />
            «Западная промзона» получает питание:
            <div>
                <li>
                    с подстанции 110/10 кВ «Северная» от ячейки № 24 по ВЛ-10
                    кВ;
                </li>
                <li>
                    с подстанции 110/10 кВ «Западная» от ячейки № 6 по ВЛ-10 кВ.
                </li>
            </div>
            Оздоровительные базы и садоводческие кооперативы получают питание:
            <div>
                <li>
                    с подстанции 35/6 кВ «Солонички» от ячеек № 5, 6 по ВЛ-6 кВ;
                </li>
                <li> с ТП 7-1 (ТОО «Adal Electro») – ЯКНО-6 по ВЛ-6 кВ;</li>
            </div>
            <span>
                Все присоединения оснащены релейной защитой и противоаварийной
                автоматикой. На отходящих фидерах распределительных пунктов
                подстанций 10/6 кВ, 35/6 кВ и 110/10 кВ установлена
                максимально-токовая защита (МТЗ).
            </span>
            <About_span>
                Трансформаторные подстанции 6-10/0.4 кВ
            </About_span>
            <span>
                В эксплуатации ТОО «Темiртау Қуат» находятся 32 трансформаторных
                подстанций мощностью от 100 кВА до 4000 кВА. <br />
                Каждая трансформаторная подстанция 6-10/0.4 кВ состоит из:
                распределительного устройства 6-10 кВ, камеры трансформаторов и
                распределительного устройства 0.4 кВ. Исполнение, комплектация и
                местоположение трансформаторных подстанций 6-10/0.4 кВ
                определяется местными условиями (наличие подъездных путей и
                потребляемая мощность). На щитах 0.4 кВ применяются
                автоматические выключатели и рубильники с предохранителями.
                Выбор типа аппарата зависит от требований эксплуатации, величины
                рабочего тока и тока к.з., от коммутационной способности
                аппарата при нормальных и аварийных режимах.
            </span>
        </Main>
    );
};

export default About;
