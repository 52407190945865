import { BiTime } from 'react-icons/bi';
import { News_article, Time_publish } from './News.styled';
import PowerPoint from './Presentation';

const News = () => {
    return (
        <News_article>
            <PowerPoint />
            <div className="w-[333px] sm:w-[700px]">
                На основании п. 639 Правил формирования тарифов утвержденных
                приказом Министра национальной экономики Республики Казахстан от
                19 ноября 2019 года №90{' '}
                <b>26.05.2023 года в 10 часов 00 минут</b> ТОО «Теміртау Қуат»
                проводит публичные слушания о повышении тарифа на уровень
                индексации в режиме ONLINE через приложение Zoom:
                <pre>Подключиться к конференции Zoom:</pre>
                <span className="phone_call">
                    <a href="https://us05web.zoom.us/j/83453206054?pwd=cFQxZldLNVdzUDdyOXBtWUFUeFBXQT09">
                        https://us05web.zoom.us/j/83453206054?pwd=cFQxZldLNVdzUDdyOXBtWUFUeFBXQT09
                    </a>
                </span>
                <pre>
                    Идентификатор конференции: 834 5320 6054 <br />
                    Код доступа: 1qaz2wsx
                </pre>
                <Time_publish>
                    <span className="span_center">
                        <BiTime />
                        25.04.2023
                    </span>
                </Time_publish>
                <br />
            </div>
            <div className="w-[333px] sm:w-[700px]">
                На основании п. 639 Правил формирования тарифов утвержденных
                приказом Министра национальной экономики Республики Казахстан от
                19 ноября 2019 года №90 24.03.2023 года в 10 часов 00 минут ТОО
                «Теміртау Қуат» проводит публичные слушания о повышении тарифа
                на уровень индексации в режиме ONLINE через приложение Zoom:
                <pre>Подключиться к конференции Zoom:</pre>
                <span className="phone_call">
                    <a href="https://us05web.zoom.us/j/85379246642?pwd=TmoyNDFicEE5ekZCWWpCdjJVb1NmZz09">
                        https://us05web.zoom.us/j/85379246642?pwd=TmoyNDFicEE5ekZCWWpCdjJVb1NmZz09
                    </a>
                </span>
                <pre>
                    Идентификатор конференции: 853 7924 6642 <br />
                    Код доступа: 1qaz2wsx
                </pre>
                <Time_publish>
                    <span className="span_center">
                        <BiTime />
                        21.02.2023
                    </span>
                </Time_publish>
                <br />
            </div>
            <div>
                <h4>Публичные слушания 3 февраля 2023 года</h4>
                На основании п.639 Правил формирования тарифов, утвержденных
                приказом Министра национальной экономики Республики Казахстан от
                19 ноября 2019 года №90 «Теміртау Қуат» информирует о проведении
                публичных слушаний 3 февраля 2023 года в связи с изменением
                тарифов на уровень индексации по следующим видам услуг:
                <br />
                <div>
                    <li>
                        подача воды по распределительным сетям – 167,57
                        тенге/м3;
                    </li>
                    <li>отведение сточных вод – 153,43 тенге/м3;</li>
                    <li>
                        передача и распределение электрической энергии – 4,43
                        тенге/кВт*ч.
                    </li>
                </div>
                <Time_publish>
                    <span className="span_center">
                        <BiTime />
                        04.01.2023
                    </span>
                </Time_publish>
                <br />
            </div>

            <div>
                <h4>Изменение тарифов с 15 марта 2022 года</h4>
                На основании п.п.1 п.1 статьи 22 Закона Республики Казахстан «О
                естественных монополиях» Департаментом Комитета по регулированию
                естественных монополий Министерства Национальной экономики
                Республики Казахстан по Карагандинской области с 15 марта 2022
                года для ТОО «Теміртау Қуат» утверждены следующие тарифы:
                <br />
                <div>
                    <li>
                        передача и распределение электрической энергии – 4,12
                        тенге/кВт*ч;
                    </li>
                    <li>отведение сточных вод — 144,26 тенге/м3.</li>
                </div>
                <Time_publish>
                    <span className="span_center">
                        <BiTime />
                        04.03.2022
                    </span>
                </Time_publish>
                <br />
            </div>

            <div>
                <h4>Изменение тарифов с 1 августа 2021 года</h4>
                На основании п.642 Правил формирования тарифов, утвержденных
                приказом Министра национальной экономики Республики Казахстан от
                19 ноября 2019 года №90 и приказа Комитета по регулированию
                естественных монополий Министерства национальной экономики
                Республики Казахстан от 28.10.2020 года №54-ОД с 01 августа 2021
                года для ТОО «Теміртау Қуат» утверждены следующие тарифы:
                <br />
                <div>
                    <li>
                        передача и распределение электрической энергии — 2,78
                        тенге/кВт*ч;
                    </li>
                    <li>отведение сточных вод — 141,62 тенге/м3;</li>
                    <li>
                        подача воды по распределительным сетям — 155,87
                        тенге/м3.
                    </li>
                </div>
                <Time_publish>
                    <span className="span_center">
                        <BiTime />
                        01.07.2021
                    </span>
                </Time_publish>
                <br />
            </div>
        </News_article>
    );
};

export default News;
